<template>
  <div class="product">
    <div class="banner" >
      <div class="banner-box">
        <div class="banner-title">产品中心</div>
        <div class="title-eng">Product Center</div>
        <div class="banner-msg">告别平庸，给您极智体验</div>
      </div>
    </div>
    <div class="main" id="smart">
      <div class="coat">
        <!-- section -->
        <div class="content" v-for="(item, index) in productList" :key="index">
          <div class="section3 flex-row justify-between">
            <div class="bd2 flex-col">
              <span class="word9">{{item.title}}</span>
            </div>
            <span class="word10">{{item.subTitle}}</span>
          </div>
          <!-- row -->          
          <div class="box" v-for="(jtem, jndex) in item.terminals" :key="jndex">
              <div class="rows" v-for="(ktem, kndex) in jtem.slave" :key="kndex">
                <div class="list1">
                  <div class="text">
                    <p>{{ktem.productModel}}</p>
                    <p>{{ktem.moduleName}}</p>
                  </div>
                  <img :src=" './images/' + ktem.icon + '.png'" alt="" />
                </div>
              </div>
              <div class="list1" style="opacity: 0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import productList from '../utils/product.json';

export default {
  data() {
    return {
      productList: productList,
    };
  },
  watch:{
    $route(){
      this.scrollMd(this.$route.query.md)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$route.query.md && this.scrollMd(this.$route.query.md)
    })

  },
  methods:{
    scrollMd(val){
        let toElement = document.getElementById(val);
        toElement.scrollIntoView();
    }
  }
};
</script>
<style scoped lang="scss">
.product {

  .banner {
    background-size: cover;
    background-position: top;
    background-position-x: center;
    background-position-y: top;
    width: 100%;
    height: 900px;
    position: relative;
    background-image: url('../assets/images/product_banner.png');

    .banner-box {
      position: absolute;
      top: 30%;
      left: 10%;
      color: #d8d8d8;

      .banner-title {
        width: 220px;
        font-size: 48px;
        letter-spacing: 5px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
      }

      .title-eng {
        width: 343px;
        font-size: 48px;
        letter-spacing: 2px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
        white-space: nowrap;
      }

      //
      .banner-msg {
        color: #999999;
        font-size: 22px;
        letter-spacing: 2px;
      }
    }
  }

  .main {
    color: #fff;
    margin-bottom: 118px;
    display: flex;
    justify-content: center;

    .coat {
      width: 1400px;

      .content {
        margin-top: 140px;

        .section3 {
          width: 200px;
          height: 90px;

          .bd2 {
            width: 4px;
            height: 32px;
            border-radius: 2px;
            background-color: rgba(255, 255, 255, 1);
            letter-spacing: 2px;

            .word9 {
              width: 180px;
              height: 30px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 28px;
              white-space: nowrap;
              line-height: 30px;
              text-align: left;
              padding-left: 22px;
            }
          }

          .word10 {
            width: 289px;
            height: 28px;
            // margin: 0 0 0 22px;
            padding-left: 22px;
            overflow-wrap: break-word;
            color: rgba(215, 215, 215, 1);
            font-size: 26px;
            white-space: nowrap;
            text-align: right;
            letter-spacing: 1px;
            line-height: 50px;
          }
        }

        .box {
          height: 380px;
          display: flex;
          justify-content: center;

          .rows {
            display: flex;
            justify-content: center;
            justify-content: space-between;
            height: 350px;
            width: 100%;

            .list1 {
              width: 460px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(54, 54, 54, 1);
              position: relative; //相对定位

              .text:hover {
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
                transition: all 0.4s;
              }

              .text {
                height: 100%;
                width: 100%;
                position: absolute; //绝对定位，字显示在上
                text-align-last: center; //文本居中
                font-weight: bold;
                text-align: center;
                font-size: 26px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                letter-spacing: 2px;
                line-height: 30px;
                color: rgba(0, 0, 0, 0);
                transition: all 0.7s;

                p {
                  text-align: center;
                }
              }

              .text p {
                margin: 10px;
              }
            }

            .list1 img {
              height: 350px;
            }
          }
        }
      }
    }
  }
}
</style>
